

  // TODO: mixin imports, especially directional
  /*

  $breakpoint-small: 640px !default;
$breakpoint-medium: 960px !default;
$breakpoint-large: 1200px !default;
$breakpoint-xlarge: 1600px !default;
   */


  .logo {
      border: 4px solid red;
      height: auto;
      width: auto;
      min-height: 100px;
      min-width: 100px;
      flex: 100px;
  }
  .login-component {
    max-width: calc(90vw - 60px);
    width: 550px;
    flex: 550px;

    @media screen and (max-width: 640px) {
      max-width: calc(90vw - 60px);
      width: 80vw;
      flex: 80vw;
    }

    @media screen and (max-width: 400px) {
      max-width: calc(90vw - 20px);
      width: 90vw;
      flex: 90vw;
    }

    .uk-card-body {
      padding-top: 0;
      padding-bottom: 0;
      @media screen and (max-width: 400px) {
        padding: 20px 10px;
      }
    }

    .uk-logo {
      display: inline-block;
    }

    .login-footer-links {
      @media screen and (max-width: 640px) {
        flex-wrap: wrap;
      }

      .link-wrap {
        @media screen and (max-width: 640px) {

          flex: 100%;
        }
      }
    }
  }
